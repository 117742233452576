import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VillaSearchDataType } from 'components/villa/search-fields-villa/search-fields-villa.types';

// Define a type for the slice state
export interface VillaSearchState {
    searchData: VillaSearchDataType | null;
    searchToken: string | null;
    expirationDate: number | null;
}

// Define the initial state using that type
const initialState: VillaSearchState = {
    searchData: null,
    searchToken: null,
    expirationDate: null,
};

export const villaSearchSlice = createSlice({
    name: 'villaSearch',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        villaSearchSaveSearchData: (state, action: PayloadAction<VillaSearchDataType>) => {
            state.searchData = action.payload;
        },
        villaSearchSaveSearchToken: (state, action: PayloadAction<string>) => {
            state.searchToken = action.payload;
        },
        villaSearchClearSearchData: state => {
            state.searchData = null;
        },
        villaSearchClearSearchToken: state => {
            state.searchToken = null;
        },
    },
});

export const { villaSearchClearSearchData, villaSearchClearSearchToken, villaSearchSaveSearchData, villaSearchSaveSearchToken } = villaSearchSlice.actions;

export default villaSearchSlice.reducer;
