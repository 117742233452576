import iraqFlag from '../images/ar-logo.svg';
import britainFlag from '../images/en-logo.svg';
export const VERSION = '1.4.3';
export enum ProfileProjectEnum {
    BETA = 'BETA',
    PRODUCTION = 'PRODUCTION',
    VISITOURIRAN = 'VISITOURIRAN',
}
export const PROFILE_PROJECT = process.env.NEXT_PUBLIC_PROJECT_PROFILE;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
export const LANDING_URL = process.env.NEXT_PUBLIC_HOME_PAGE_LANDING_URL;

export const postalCodeLocale = [
    'AD',
    'AT',
    'AU',
    'BE',
    'BG',
    'BR',
    'CA',
    'CH',
    'CN',
    'CZ',
    'DE',
    'DK',
    'DZ',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IL',
    'IN',
    'IS',
    'IT',
    'JP',
    'KE',
    'LI',
    'LT',
    'LU',
    'LV',
    'MX',
    'MT',
    'NL',
    'NO',
    'NZ',
    'PL',
    'PR',
    'PT',
    'RO',
    'RU',
    'SA',
    'SE',
    'SI',
    'SK',
    'TN',
    'TW',
    'UA',
    'US',
    'ZA',
    'ZM',
];

export type languagesType = {
    value: string;
    text: string;
    image: any;
};

export const LANGUAGES: languagesType[] = [
    {
        value: 'ar',
        text: 'العربیه',
        image: iraqFlag,
    },
    {
        value: 'en',
        text: 'English',
        image: britainFlag,
    },
    {
        value: 'fa',
        text: 'فارسی',
        image: britainFlag,
    },
];
