import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import authReducer from '@travel-platform/commons/src/redux/auth-slice';
import flightOfferReducer from './flight-offer-slice';
import passengersFlightReducer from './passengers-flight-slice';
import passengersHotelReducer from './passengers-hotel-slice';
import hotelInfoReducer from './hotel-info-slice';
import currencyReducer from './currency-slice';
import cartItemReducer from './cartItem-slice';
import countriesReducer from './countries-slice';
import flightSearchesReducer from './flight-searches-slice';
import hotelSearchesReducer from './hotel-searchs-slice';
import exchangeRateReducer from './exchange-rate-slice';
import airlinesInfoReducer from './airlines-info-slice';
import airportsInfoReducer from './airports-info-slice';
import loginReducer from '@travel-platform/commons/src/redux/login-slice';
import landingsReducer from '@travel-platform/commons/src/redux/cms-slice';
import snackbarAlertReducer from '@travel-platform/commons/src/redux/snackbar-alert-slice';
import { VERSION } from '../components/common/constant/constant';
import flightSearchReducer from './flight-search-slice';
import searchExpireDateReducer from './search-expire-date-slice';
import hotelSearchReducer from './hotel-search-slice';
import villaSearchReducer from './villa-search-slice';
import recentSearchReducer from './recent-searches-slice';
import cartReducer from '@travel-platform/commons/src/redux/cart-slice';
import travelersReducer from '@travel-platform/commons/src/redux/travelers-slice';
// import HotelSearchHistoryReducer from './hotel-search-history-slice';
import HotelSearchHistoryReducer from '@travel-platform/commons/src/redux/hotel-search-history-slice';
import discountCodeReducer from './discount-slice';

const translateTimeout = 84600000;
const SetTransformDaily = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: any) => {
        return inboundState instanceof Array
            ? inboundState.map((item: any) =>
                  item._createAt
                      ? item
                      : {
                            ...item,
                            _createAt: Date.now(),
                        }
              )
            : inboundState;
    },
    // transform state being rehydrated
    (outboundState: any) => {
        return outboundState instanceof Array ? outboundState.filter((item: any) => item._createAt && item._createAt + translateTimeout > Date.now()) : outboundState;
    }
);
const SetTransformVersion = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: any) => {
        const changedData = {
            version: VERSION,
            data: inboundState,
        };
        return changedData;
        // return (inboundState instanceof Array) ? inboundState.map((item: any) => (item._createAt) ? item : { ...item, _createAt: Date.now() }) : inboundState;
    },
    // transform state being rehydrated
    (outboundState: any) => {
        if (!outboundState.version || outboundState.version !== VERSION) {
            return null;
        } else {
            return outboundState.data;
        }
        // return (outboundState instanceof Array) ? outboundState.filter((item: any) => (item._createAt && ((item._createAt + translateTimeout) > Date.now()))) : outboundState;
    }
);
const currencyConfigSessionStorage = {
    key: 'currency',
    storage,
};
const loginConfigSessionStorage = {
    key: 'login',
    storage,
};
const hotelSearchesConfigSessionStorage = {
    key: 'hotelSearches',
    storage,
    transforms: [SetTransformVersion],
};
const recentSearchLocalStorage = {
    key: 'recentSearches',
    storage,
    transforms: [SetTransformVersion],
};
const rootPersistConfigLocalStorage = {
    key: 'root',
    storage,
    transforms: [SetTransformVersion],
};
const exchangeRatePersistConfigLocalStorage = {
    key: 'exchangeRate',
    storage,
};
const cartItemPersistConfigSessionStorage = {
    key: 'cartItem',
    storage,
    transforms: [SetTransformVersion],
};
const flightOfferPersistConfigSessionStorage = {
    key: 'flightOffer',
    storage: storageSession,
};
const passengersFlightPersistConfigSessionStorage = {
    key: 'passengersFlight',
    storage: storageSession,
};
const passengersHotelPersistConfigSessionStorage = {
    key: 'passengersHotel',
    storage: storageSession,
};
const hotelInfoPersistConfigSessionStorage = {
    key: 'hotelInfo',
    storage: storageSession,
};
const airlinesInfoPersistConfigLocalStorage = {
    key: 'airlinesInfo',
    storage,
    transforms: [SetTransformDaily, SetTransformVersion],
};
const airportsInfoPersistConfigLocalStorage = {
    key: 'airportsInfo',
    storage,
    transforms: [SetTransformDaily, SetTransformVersion],
};
const countriesPersistConfigLocalStorage = {
    key: 'countries',
    storage,
    transforms: [SetTransformDaily, SetTransformVersion],
};
const searchesFlightPersistConfigLocalStorage = {
    key: 'flightSearches',
    storage,
    transforms: [SetTransformVersion],
};
const flightSearchPersistConfigSessionStorage = {
    key: 'flightSearch',
    storage: storageSession,
};
const searchExpireDatePersistConfigSessionStorage = {
    key: 'searchExpireDate',
    storage: storageSession,
};
const hotelSearchPersistConfigSessionStorage = {
    key: 'hotelSearch',
    storage: storageSession,
};
const villaSearchPersistConfigSessionStorage = {
    key: 'villaSearch',
    storage: storageSession,
};
const permissionPersistConfigSessionStorage = {
    key: 'jwtAccess',
    storage,
};
const travelersPersistConfigLocalStorage = {
    key: 'travelers',
    storage,
};
const cartPersistConfigLocalStorage = {
    key: 'cart',
    storage,
};
const HotelSearchHistoryLocalStorage = {
    key: 'searchHistory_hotel',
    storage,
};
const DiscountDataLocalStorage = {
    key: 'searchHistory_hotel',
    storage,
};

const rootReducer = combineReducers({
    login: persistReducer(loginConfigSessionStorage, loginReducer),
    snackbarAlert: snackbarAlertReducer,
    currency: persistReducer(currencyConfigSessionStorage, currencyReducer),
    hotelSearches: persistReducer(hotelSearchesConfigSessionStorage, hotelSearchesReducer),
    recentSearches: persistReducer(recentSearchLocalStorage, recentSearchReducer),
    auth: persistReducer(rootPersistConfigLocalStorage, authReducer),
    exchangeRate: persistReducer(exchangeRatePersistConfigLocalStorage, exchangeRateReducer),
    cartItem: persistReducer(cartItemPersistConfigSessionStorage, cartItemReducer),
    flightOffer: persistReducer(flightOfferPersistConfigSessionStorage, flightOfferReducer),
    passengersFlight: persistReducer(passengersFlightPersistConfigSessionStorage, passengersFlightReducer),
    passengersHotel: persistReducer(passengersHotelPersistConfigSessionStorage, passengersHotelReducer),
    hotelInfo: persistReducer(hotelInfoPersistConfigSessionStorage, hotelInfoReducer),
    airlinesInfo: persistReducer(airlinesInfoPersistConfigLocalStorage, airlinesInfoReducer),
    airportsInfo: persistReducer(airportsInfoPersistConfigLocalStorage, airportsInfoReducer),
    countries: persistReducer(countriesPersistConfigLocalStorage, countriesReducer),
    flightSearches: persistReducer(searchesFlightPersistConfigLocalStorage, flightSearchesReducer),
    flightSearch: persistReducer(flightSearchPersistConfigSessionStorage, flightSearchReducer),
    searchExpireDate: persistReducer(searchExpireDatePersistConfigSessionStorage, searchExpireDateReducer),
    hotelSearch: persistReducer(hotelSearchPersistConfigSessionStorage, hotelSearchReducer),
    villaSearch: persistReducer(villaSearchPersistConfigSessionStorage, villaSearchReducer),
    cart: persistReducer(cartPersistConfigLocalStorage, cartReducer),
    travelers: travelersReducer,
    landings: persistReducer(travelersPersistConfigLocalStorage, landingsReducer),
    HotelSearchHistory: persistReducer(HotelSearchHistoryLocalStorage, HotelSearchHistoryReducer),
    discountCodeData: discountCodeReducer,
});

const persistedReducer = rootReducer;

export const store = configureStore({
    reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
