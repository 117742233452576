import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VerifyDiscountCodeResponse } from 'services/booking/booking.types';

// Define a type for the slice state
export interface discountState {
    discountCodeData: VerifyDiscountCodeResponse | null;
}

// Define the initial state using that type
const initialState: discountState = {
    discountCodeData: null,
};

export const discountSlice = createSlice({
    name: 'discountData',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        discountDataSave: (state, action: PayloadAction<VerifyDiscountCodeResponse>) => {
            state.discountCodeData = action.payload;
        },
        discountDataClear: state => {
            state.discountCodeData = null;
        },
    },
});

export const { discountDataSave, discountDataClear } = discountSlice.actions;

export default discountSlice.reducer;
